import Map, {MapLayerMouseEvent, NavigationControl, Popup} from 'react-map-gl';
import {useRef} from 'react';
import mapboxConfig from '../../insight-container/map/map-libs/mapbox-config';
import {LayerPropsWithType} from './layers/types';
import LayerFinder from './layers';
import {bbox} from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
    layers: LayerPropsWithType[]
    onHoverCB?: (event:MapLayerMouseEvent, features?:any) => void;
    onClickCB?: (event:MapLayerMouseEvent) => void;
    interactiveLayers?: string[]
    popupOptions?: any
    style?: any
    mapStyle?: string
    dynamicBounds?: boolean
    viewState?: {
        longitude: number
        latitude: number
        zoom: number
        maxZoom: number
        minZoom: number
    }
}

export default function BaseMap(props:Props) {
    const {layers, onHoverCB, onClickCB, interactiveLayers, popupOptions, style, mapStyle, dynamicBounds = false, viewState} = props;
    const {name, disabledText, ...rest} = popupOptions ?? {};

    const mapRef = useRef<any>();


    function onMapLoad() {
        if (dynamicBounds) {
            const geojsonLayer = layers.find((l) => l.layerType === 'choropleth');
            if (geojsonLayer && geojsonLayer.data) {
                const bounds = bbox(geojsonLayer.data);
                const map = mapRef.current.getMap();
                map.fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]]);
            }
        }
    }
    

    const defaultStyle = {width: 800, height: 600};

    const initialViewState = {
        longitude: mapboxConfig.defaultLongitude,
        latitude: mapboxConfig.defaultLatitude,
        zoom: 3 || mapboxConfig.defaultZoom,
        maxZoom: 6,
        minZoom: 2.2
    };

    return (
        <Map
            style={style || defaultStyle}
            mapStyle={mapStyle || mapboxConfig.defaultStyle}
            mapboxAccessToken={mapboxConfig.accessToken}
            onLoad={onMapLoad}
            ref={mapRef}
            onClick={onClickCB}
            interactiveLayerIds={interactiveLayers || [String(layers[0].id)]}
            onMouseMove={(e)=>{
                const features = mapRef.current.queryRenderedFeatures(e.point, {
                    layers: interactiveLayers || [String(layers[0].id)]
                });
                onHoverCB && onHoverCB(features);
            }}
            initialViewState={viewState || initialViewState}
            attributionControl={false}
        >
            {layers.map((l) => (
                <LayerFinder key={l.id} {...l} />
            ))}
            {popupOptions && (
                <Popup {...rest} closeButton={false} closeOnClick={false}>
                    <p className='text-sm'>{name}</p>
                    {disabledText && <p className='text-sm'>{disabledText}</p> }
                </Popup>
            )}
            <NavigationControl position='top-left'/>
        </Map>
    );
}
