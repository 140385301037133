import {makeAutoObservable} from 'mobx';
import {createContext} from 'react';

const USE_NEW_DESIGN = !!localStorage.getItem('useNewDesign') || false;
//change this to true when we're ready to roll out the new design

const MY_CONTENT_PREFERENCE_KEY = 'my-content-preference';
export class HeaderStore {
    static empty() {
        return new HeaderStore(!!process.env.REACT_APP_NEW_DESIGN || USE_NEW_DESIGN);
    }

    private _useNewDesign: boolean;
    private _showMyContent: boolean;

    constructor(useNewDesign: boolean) {
        this._useNewDesign = useNewDesign;
        this._showMyContent = false;
        makeAutoObservable(this);
    }

    set useNewDesign(bool: boolean) {
        this._useNewDesign = bool;
    }

    get useNewDesign() {
        return this._useNewDesign;
    }

    get showMyContent() {
        return this._showMyContent;
    }

    set showMyContent(bool: boolean) {
        this._showMyContent = bool;
    }

    setShowMyContentPreference(bool: boolean) {
        sessionStorage.setItem(MY_CONTENT_PREFERENCE_KEY, bool.toString());
    }

    getShowMyContentPreference() {
        const preference = sessionStorage.getItem(MY_CONTENT_PREFERENCE_KEY);
        return preference ? preference === 'true' : true;
    }

}
const HeaderContext = createContext(HeaderStore.empty());
export {HeaderContext};