import Choropleth from './choropleth';
import Points from './points';
import {LayerPropsWithType} from './types';


function findLayer(type: string) {
    if (type === 'point')
        return Points;
    else if (type === 'choropleth')
        return Choropleth;
    throw new Error('Unknown layer type: ' + type);
}



export default function LayerFinder(props: LayerPropsWithType) {
    const {layerType, ...rest} = props;

    const FoundLayer = findLayer(layerType);

    return <FoundLayer {...rest}/>;
}