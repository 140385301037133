import {FeatureCollection} from 'geojson';
import {FillPaint} from 'mapbox-gl';

export type LayerProps = {
    id: number | string;
    data: FeatureCollection | undefined,
    paint?: FillPaint;
}

export type LayerPropsWithType = LayerProps & {
    layerType: Layers;
}

export enum Layers {
    point = 'point',
    choropleth = 'choropleth'
}

export type BaseMapProps = {
    children: LayerPropsWithType[]
}