import {Source, Marker, useMap, Layer} from 'react-map-gl';
import {LayerProps} from './types';
import {useState, useEffect} from 'react';
import {useDebounce} from './layer-utils';

export default function Points(props: LayerProps) {
    const {data, id, paint} = props;
    const color = paint?.['fill-color'];


    const {current: map} = useMap();
    const [zoom, setZoom] = useState(map ? map.getZoom() : 0);

    const debouncedZoom = useDebounce(zoom, 250);

    useEffect(() => {
        if (map) {
            const handleZoom = () => {
                setZoom(map.getZoom());
            };

            map.on('zoom', handleZoom);

            return () => {
                map.off('zoom', handleZoom);
            };
        }
    }, [map]);

    // Replace zoom with debouncedZoom
    const size = debouncedZoom * 3.5;

    return (
        <Source id={String(id)} key={id} type='geojson' data={data}>
            <Layer
                id={String(id)}
                type='circle'
                paint={{'circle-opacity': 0, 'circle-radius': size}}
            />
            {data!.features.map((feature: any, index) => (
                <Marker
                    key={index}
                    longitude={feature.geometry.coordinates[0]}
                    latitude={feature.geometry.coordinates[1]}
                >
                    <svg
                        fill='none'
                        width={size}
                        height={size}
                        viewBox='0 0 15 15'
                        xmlns='http://www.w3.org/2000/svg'
                        style={{transform: 'translate(-50%, -50%)'}}
                    >
                        <path
                            fill={String(color)}
                            d='M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703 C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z'
                        />
                    </svg>
                </Marker>
            ))}
        </Source>
    );
}
